.main-section {
    padding-top: 1rem;
    margin-bottom: -4rem;
}

.main-section img {
    width: 100%;
    height: auto;
    /* margin-left: 5rem; */
    /* padding-right: 10rem; */
    align-items: center;
    justify-content: center;
    object-fit: contain;
    /* border-radius: 0.5rem; */
}

.main-section h2 {
    position: relative;
    text-align: center;
    top: -14rem;
    color: white;
    text-transform: capitalize;
    /* font-size: 25px; */
    font-family: "josefin sans", sans-serif;
}

.main-section h3 {
    position: relative;
    text-align: center;
    top: -13rem;
    color: white;
    text-transform: capitalize;
    /* font-size: 25px; */
    font-family: "josefin sans", sans-serif;
}


.introduction,
.features {
    margin: 2rem;
    margin-left: 6.5rem;
}

.introduction span,
.features span {
    font-family: "josefin sans", sans-serif;
    /* font-size: 25px; */
    font-weight: 600;
    background-image: linear-gradient(to right, green, rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.introduction hr,
.features hr {
    color: darkgreen;
    border: 2px solid;
    margin-top: 0;
    max-width: 10rem;
    opacity: 0.7;
    border-radius: 3px;
}
