.container-notice {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adds a semi-transparent background */
    z-index: 9999;
    /* Ensures the notice is displayed on top of other elements */
}

.pop-section {
    background: white;
    position: fixed;
    width: 38%;
    /* height: 50%; */
    top: 3%;
    left: 32%;
    border-radius: 5px;
    border: 1px solid darkorange;
    /* display: none; */
    z-index: 1000;
}

.pop-head {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}

.pop-notice {
    /* margin: 1rem; */
    background: darkorange;
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
}

.close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    /* font-size: 1.5rem; */
    background-color: darkorange;
    border: none;
    cursor: pointer;
    padding: 1rem;
}

.close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    /* padding: 1rem; */
    border: none;
    background-color: white;
}

.cross {
    position: relative;
    font-size: 1.5rem;
    color: darkorange;
}

.cross:hover {
    color: black;
}

.pop-body h2 {
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 1rem;
    font-size: 1.3rem;
    font-family: "josefin sans", sans-serif;
}

.pop-body p {
    justify-content: center;
    align-items: center;
    text-align: justify;
    margin: 1rem;
    font-size: 1.3rem;
}

.pop-body img {
    width: 100%;
    /* margin-bottom: 1rem; */
    /* height: 20rem; */
}

.pop-body .img-btn {
    width: 100%;
}

@media(max-width:768px) {
    .pop-section {
        width: 80%;
        top: 25%;
        left: 7%;
    }

    .pop-body h2 {
        margin: 1rem;
        font-size: 1rem;
        font-family: "josefin sans", sans-serif;
    }
}