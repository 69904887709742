.partition {
    display: flex;
    /* width: 80%; */
}

.partition1 {
    width: 50%;
}

.partition2 {
    width: 50%;
}

.contact-address,
.branch-address {
    margin-left: 5rem;
    padding: 1rem;
    padding-left: 2rem;
    width: 80%;
    font-family: "Andika", sans-serif;
    background-color: white;
    border-radius: 5px;
}

.contact-address h2,
.branch-address h2 {
    font-size: 25px;
}

.branch-address h2 span {
    margin-left: 0.5rem;
}

.contact-address h3,
.branch-address h3 {
    font-size: 20px;
    margin: 1rem 1.5rem;
}

.contact-address h3 span {
    margin-left: 4rem;
}

.branch-address img {
    width: 25px;
}

.branch-address h3 span {
    margin-left: 2rem;
}

.form-1 {
    margin-top: 2rem;
}

.query1 {
    margin-top: 8.3rem;
    margin-bottom: 1rem;
    font-family: "josefin sans", sans-serif;
    background-image: linear-gradient(to right, darkorange, white);
    padding-top: 10px;
    padding-bottom: 1px;
    border-radius: 5px;
    width: 85%;
}

.query1 h2 {
    font-size: 25px;
}

.query1 span {
    margin: 2rem;
}

.contact-form {
    /* margin-top: 7.5rem; */
    width: 85%;
}

.contact-form Form {
    /* margin-left: 2rem; */
    font-family: "Andika", sans-serif;
    font-size: 18px;
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
}

.contact-form Button {
    /* margin-bottom: 1.5rem; */
    background-color: rgb(255, 182, 93);
    border-color: rgb(255, 182, 93);
    color: black;
}

.contact-form Button:hover {
    background-color: darkorange;
    border-color: rgb(255, 182, 93);
    color: white;
}

.error-message {
    font-size: 14px;
}

@media (max-width:768px) {
    .partition {
        flex-direction: column;
        /* background-color: red; */
    }
    .partition1 {
        width: 80%;
    }
    
    .partition2 {
        width: 100%;
        /* background-color: red; */
    }

    .contact-address,
    .branch-address {
        margin-left: 3rem;
        padding: 0;
        padding-left: 0;
        width: 100%;
        border-radius: 5px;
        /* background-color: blue; */
    }

    .contact-address h2,
    .branch-address h2 {
        font-size: 18px;
    }

    .branch-address h2 span {
        margin-left: 0.5rem;
    }

    .contact-address h3 {
        font-size: 16px;
        margin: 1rem 1.5rem;
    }
    .branch-address h3 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 1rem;
    }

    .contact-address h3 span {
        margin-left: 3rem;
    }
    .form-1 {
        margin-top: 0;
    }
    .query1 {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
        font-family: "josefin sans", sans-serif;
        background-image: linear-gradient(to right, darkorange, white);
        padding-top: 10px;
        padding-bottom: 1px;
        border-radius: 5px;
        width: 80%;
    }
    .contact-form Form {
        margin-left: 1rem;
        font-family: "Andika", sans-serif;
        font-size: 18px;
        background-color: white;
        padding: 1rem;
        border-radius: 5px;
    }
}