.sticky {
    position: fixed;
    top: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
    z-index: 100;
    background: rgb(255, 255, 255);
    /* background-image: linear-gradient(to right, white, darkorange, white, white, white); */
    width: 100%;
    border-radius: 6px;
    transition: 0.8s ease-in;
}

.sticky .navbar-logo {
    margin-top: 2rem;
    width: 105px;
    height: 50px;
    margin-left: 3rem;
}

.navbar-brand {
    margin-top: -2rem;
    margin-left: 2rem;
}

.navbar-logo {
    width: 140px;
    height: 70px;
}

Nav {
    font-family: "josefin sans", sans-serif;
    font-size: 18px;
}

.active {
    color: darkorange !important;
}

.link,
#collasible-nav-dropdown {
    color: black !important;
    /* border-radius: 3px; */
    /* transition: all 0.3s ease-in-out; */
    /* border-color: red; */
}

.link:hover {
    color: darkorange !important;
    /* background-color: darkorange !important; */
}

.link:hover .link-2,
.link:hover #collasible-nav-dropdown {
    color: darkorange !important;
    /* background-color: darkorange !important; */
}

#collasible-nav-dropdown:hover .link {
    color: darkorange !important;
}

.link-item {
    color: black !important;
    /* transition: all 0.3s ease-in-out !important; */
    /* border-radius: 3px; */
    background-color: white !important;
    font-size: 18px !important;
}

.link-item:hover {
    color: darkorange !important;
}

@media(max-width:768px) {

    .iconnav {
        position: relative;
        left: 18rem;
        top: -4.5rem;
        margin-bottom: -5rem;
    }

    .sticky .navbar-logo {
        margin-top: 2rem;
        width: 105px;
        height: 50px;
        margin-left: 2rem;
    }

    .containernav {
        text-align: right;
    }
}