h5,
address,
cite {
    color: black;
}

.footer-section-3 {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.footer-section-3 a {
    text-decoration: none;
    color: black;
}

.footer-section-3 a:hover {
    color: rgb(188, 103, 0);
}

.hr-footer {
    color: orange;
    opacity: 0.8;
}

@media(max-width: 768px) {

    .card-body {
        width: 80%;
        margin-right: 2rem;
        margin-left: 2rem;
    }
}