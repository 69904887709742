/* @import url(//db.onlinewebfonts.com/c/04559eae8b7926d003f9f2e036c9e076?family=Blippo); */

.section-1 {
    display: flex;
    /* flex-wrap: inherit; */
    margin-left: 4.5rem;
    margin-right: 5rem;
    border-radius: 3px;
    /* width: 100%; */
}

.left {
    padding-top: 2rem;
    width: 50%;
}

.banner-name {
    /* display: flex; */
    align-items: center;
    justify-content: left;
    padding-left: 2rem;
    margin-top: 1rem;
    margin-right: 5rem;
    min-width: 300px;
    font-family: "josefin sans";

}

.section-1 .img {
    text-align: center;
    justify-content: center;
    align-items: center;
    /* width: 60%; */
    /* margin-top: 2rem; */
    margin: auto;
    object-fit: cover;
}

.section-1 .img .hero-img {
    /* display: block; */
    /* height: 200px; */
    width: 70%;
    margin: auto;
    padding-top: 3rem;
    background-repeat: no-repeat;
    animation: leftRight 2s ease-in-out infinite alternate-reverse both;
    border-radius: 5px;
}

@-webkit-keyframes leftRight {
    from {
        transform: translateX(0);
    }

    from {
        transform: translateX(10%);
    }
}

@keyframes leftRight {
    from {
        transform: translateX(0);
    }

    from {
        transform: translateX(10%);
    }
}

.banner-name span {
    font-size: 25px;
    font-weight: 600;
    /* background-image: linear-gradient(to right, green, rgb(230, 115, 15)); */
    background-image: linear-gradient(to right, rgb(223, 113, 16), rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-name text {
    font-size: 22px;
    /* background-image: linear-gradient(to right, green, rgb(230, 115, 15)); */
    background-image: linear-gradient(to right, rgb(0, 72, 0), rgb(0, 72, 0));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.banner-name text2 {
    font-size: 20px;
    border: 1px solid darkorange;
    padding: 0.5rem;
    border-radius: 5px;
    /* background-color: darkorange; */
    /* color: black; */
    box-shadow: 3px 3px 3px darkorange;
    background-image: linear-gradient(to right, rgb(223, 113, 16), rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.divider {
    padding: 0 2rem;
    /* width: 25rem; */
}

.divider hr {
    border: 2px solid darkgreen;
    border-radius: 3px;
    /* margin-top: 5px; */
    max-width: 25rem;
    opacity: 0.7;
}

.vision {
    background: white;
    padding: 1rem;
    margin-left: 2rem;
    border-radius: 5px;
}

.vision h6 {
    margin: 1rem;
    margin-top: 0;
    font-family: "josefin sans", sans-serif;
    text-align: justify;
    font-size: 18px;
}

.category,
.section-2,
.section-3,
.section-4,
.section-5 {
    margin-left: 1.5rem;
}

@media (max-width: 768px) {
    .section-1 .img .hero-img {
        display: none;
    }

    .section-1 .left {
        width: 95%;
    }

    .section-1 {
        display: flex;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .left {
        padding-top: 1rem;
    }

    .banner-name {
        /* display: flex; */
        align-items: center;
        justify-content: left;
        padding-left: 2rem;
        margin-top: 1rem;
        margin-right: 1rem;
        font-family: "josefin sans";
    }

    .vision {
        background: white;
        padding: 0;
        margin-left: 1rem;
        width: 90%;
    }
}