.header {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
    padding: 0.5rem;
    flex-wrap: wrap;
    font-family: "josefin sans";
    margin-bottom: -2rem;
}

.header i {
    margin: 0.5rem;
}

.header a {
    text-decoration: none;
    color: black;
}

.header a:hover,
.header i:hover {
    color: darkorange;
}

.header .contact {
    margin-right: 1rem;
}

.hr {
    padding: 1rem;
    color: darkorange;
    opacity: 1;
}

.advertise {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
}

.advertise img {
    width: 80%;
    opacity: 1;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .advertise {
        width: 100%;
    }

    .advertise img {
        width: 95%;
    }
}