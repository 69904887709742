.content-img img {
    border-radius: 5px;
}

.h4-service-content {
    margin-left: 8rem;
    font-family: "Andika", sans-serif;
}

.service-content {
    display: flex;
    /* margin: 2rem; */
}

.service-content-blank span {
    margin: 3rem;
}

.service-content .service-content-img img {
    width: 60%;
    margin-left: 5rem;
    margin-top: 1rem;
    border-radius: 5px;
    /* background-repeat: no-repeat; */
    object-fit: cover;
    animation: leftRight 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes leftRight {
    from {
        transform: translateX(0);
    }

    from {
        transform: translateX(10%);
    }
}

@keyframes leftRight {
    from {
        transform: translateX(0);
    }

    from {
        transform: translateX(10%);
    }
}

.service-content .service-content-list {
    margin-left: 5rem;
    margin-right: 3rem;
    width: 50%;
}

.accordion-service {
    margin-right: 5rem;
    width: 100%;
}

.accordion-service>li {
    list-style: none;
    width: 100%;
    margin: 20px;
    padding: 10px;
    border-radius: 8px;
    /* background: darkorange; */
    background-image: linear-gradient(to right, darkorange, white);
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.15);
}

.accordion-service>li label {
    display: flex;
    align-items: center;
    /* padding: 10px; */
    font-size: 20px;
    font-weight: 500;
    /* font-weight: bold; */
    cursor: pointer;
    font-family: "Andika", sans-serif;
    /* width: 100%; */
}

.accordion-service>li label::before {
    content: '+';
    margin-right: 10px;
    font-size: 24px;
    font-weight: 600;
}

.accordion-service input[type="radio"] {
    display: none;
}

.accordion-service .content-service1 {
    /* color: #555; */
    padding: 0 10px;
    line-height: 26px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s;
}

.accordion-service .content-service1 li {
    margin-left: 2rem;
    font-family: "Andika", sans-serif;
    font-size: 17px;
    list-style-type: square;
    margin-bottom: 1rem;
    font-weight: 500;
}

.accordion-service input[type="radio"]:checked+label+.content-service1 {
    max-height: 1000px;
    padding: 10px 10px 20px;
}

.accordion-service input[type="radio"]:checked+label::before {
    content: '-';
}

@media (max-width: 768px) {

    .service-content .service-content-img img {
        display: none;
    }

    .service-content .service-content-list {
        margin-left: 0;
        margin-right: 0;
        width: 80%;
    }

    .accordion-service {
        margin-right: 0;
        width: 100%;
    }

    .h4-service-content {
        margin-left: 2rem;
        width: 87%;
        font-family: "Andika", sans-serif;
    }

    .service-content {
        display: flex;
        flex-direction: column;
        /* background-color: gray; */
        /* margin: 2rem; */
    }

    .service-content .content-img {
        width: 80%;
        margin-left: 2rem;
        padding: 1rem;
        /* background-color: red; */
    }

    .service-content .content-img img {
        width: 100%;
        display: block;
    }
}

.p1 {
    margin: 2rem;
}