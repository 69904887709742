.partners {
    margin-left: 4.5rem;
    margin-top: 1.2rem;
}

.partners span {
    font-family: "josefin sans";
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to right, green, rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.partners hr {
    color: darkgreen;
    border: 2px solid;
    margin-top: 0;
    max-width: 10rem;
    opacity: 0.7;
    border-radius: 3px;
}

.partners-body {
    /* font-family: "josefin sans", sans-serif; */
    margin-right: 2.5rem;
}

.partners-body h3 {
    font-size: 20px;
    margin-left: 6.5rem;
    margin-bottom: 2rem;
    font-family: "Andika", sans-serif;
}

.slide-container-partners {
    width: 90%;
    padding: 10px 10px;
}

.slide-content-partners {
    margin: 0 40px;
    overflow: hidden !important;
}

.card {
    border-radius: 10px;
    background-color: white;
    min-width: 250px !important;
}


.image-content-partners,
.card-content-partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
    justify-content: space-between;
    text-align: center;
    /* width: 100%; */
}

.image-content-partners {
    row-gap: 5px;
    padding: 15px 0;
}

.card-image-partners {
    position: relative;
    height: 150px;
    max-width: 250px;
    width: 100%;
}

.card-image-partners .card-img-partners {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.partners-body .swiper {
    padding-bottom: 3rem !important;
}

.swiper-pagination-bullet {
    background-color: orange !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background-color: darkorange !important;
}

@media (max-width: 768px) {
    .partners {
        margin-left: 0.5rem;
        margin-top: 1rem;
    }

    .partners-body h3 {
        font-size: 20px;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
        font-family: "Andika", sans-serif;
    }

    .slide-content-partners {
        margin: 0 10px !important;
    }
}