.feedback-content {
    display: flex;
    width: 100%;
}

.feedback-form {
    margin-left: 2rem;
    width: 50%;
}

.feedback-form Form {
    margin-left: 4.5rem;
    padding: 1rem;
    font-family: "Andika", sans-serif;
    font-size: 18px;
    background-color: white;
}

.feedback-form Button {
    margin-bottom: 1.5rem;
    background-color: rgb(255, 182, 93);
    border-color: rgb(255, 182, 93);
    color: black;
}

.feedback-form Button:hover {
    background-color: darkorange;
    border-color: rgb(255, 182, 93);
    color: white;
}

.feedback-img {
    width: 50%;
    justify-content: center;
    align-items: center;
}

.feedback-img img {
    border-radius: 5px;
    width: 80%;
    margin: 0 2.5rem;
}

@media (max-width:768px) {
    .feedback-img {
        display: none;
    }

    .feedback-form {
        margin-left: 0;
        margin-top: -2rem;
        width: 85%;
    }

    .feedback-form Form {
        margin-left: 2rem;
    }
}