.products {
    margin-left: 4.5rem;
    margin-top: 1.2rem;
}

.products span {
    font-family: "josefin sans";
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to right, green, rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.products hr {
    color: darkgreen;
    border: 2px solid;
    margin-top: 0;
    max-width: 10rem;
    opacity: 0.7;
    border-radius: 3px;
}

.product-body {
    font-family: "Andika", sans-serif;
    border-radius: 5px;
    /* background-color: gray; */
    margin-right: 3.1rem;
    /* padding-bottom: 5rem; */
}

.product-body h3 {
    font-size: 20px;
    margin-left: 6.5rem;
    margin-bottom: 2rem;
    /* font-family: "Andika", sans-serif; */
}

.slide-container-product {
    /* max-width: 1120px; */
    width: 90%;
    /* background-color: gray; */
    padding: 10px 10px;
    /* margin-right: 5rem; */
}

.slide-content-product {
    margin: 0 40px;
    overflow: hidden !important;
}

.card {
    /* width: 280px; */
    border-radius: 10px;
    background-color: white;
    min-width: 250px !important;
}

.image-content-product,
.btn-content-product,
.card-content-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
    justify-content: space-between;
    text-align: center;
    /* width: 100%; */
}

.image-content-product {
    row-gap: 5px;
    padding: 15px 0;
}

.card-image-product {
    position: relative;
    height: 150px;
    max-width: 250px;
    width: 100%;
}

.card-image-product .card-img-product {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.name-product {
    font-size: 22px;
    /* font-weight: 300;0 */
    color: black;
    margin-top: 10px;
    margin-bottom: -10px;
    font-family: "josefin sans", sans-serif;
}

.button-product {
    border: none;
    font-size: 16px;
    color: white;
    padding: 5px 10px;
    background-color: #6088f6;
    border-radius: 5px;
    margin: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    /* transition: 0.5s ease-in-out; */
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.button-product a {
    text-decoration: none;
    color: white;
}

.button-product:hover {
    background: #124ff8;
}

.button-product a:hover {
    color: white;
}

.card:hover .image-content-product {
    opacity: 0.5;
}

.card:hover .button-product {
    opacity: 1;
}

.product-body .swiper {
    padding-bottom: 3rem !important;
    /* margin-left: 3rem !important; */
    /* padding-left: 2rem; */

}

.swiper-pagination-bullet {
    background-color: orange !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background-color: darkorange !important;
}

.swiper-button-prev,
.swiper-button-next {
    color: orange !important;
}

.swiper-button-prev {
    left: 0 !important;
}

.swiper-button-next {
    right: 0 !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    color: darkorange !important;
}

.swiper-button-prev::before,
.swiper-button-prev::after,
.swiper-button-next::before,
.swiper-button-next::after {
    font-size: 25px !important;
}

@media (max-width: 768px) {
    .products {
        margin-left: 0.5rem;
        margin-top: 1rem;
    }

    .product-body {
        font-family: "Andika", sans-serif;
        border-radius: 5px;
        /* background-color: gray; */
        margin-right: 3rem;
        /* padding-bottom: 5rem; */
    }

    .product-body h3 {
        font-size: 20px;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
        /* font-family: "Andika", sans-serif; */
    }

    .slide-content-product {
        margin: 0 10px !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none !important;
    }
}