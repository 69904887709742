.category-stats {
    width: auto;
    font-family: "open sans", sans-serif;
    /* background: white; */
    color: #a1a1a1;
    margin-bottom: -1rem;
    /* margin-left: 5.7rem; */
    border-radius: 5px;
}

.grid-x {
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap; */
    justify-content: center;
    width: 100%;
}

.category-stats .inner {
    /* background: white; */
    width: 100%;
    /* max-width: 800px; */
    padding: 15px 0;
    margin: 0 auto;
}

.category-stats .cell {
    font-size: 30px;
    line-height: 1.2;
    margin: 0 5rem;
    width: 10%;
}

.category-stats span {
    display: block;
    font-size: 17px;
    text-transform: uppercase;
    font-family: "josefin sans", sans-serif;
    font-weight: 700;
}

@media (max-width: 768px) {
    .category-stats {
        width: 80%;
        margin: 0;
    }

    .category-stats .inner {
        padding: 0;
        margin: 0;
    }

    .category-stats .cell {
        font-size: 18px;
        line-height: 1.5;
        margin: 0 2rem;
        width: 10%;
    }

    .category-stats span {
        font-size: 10px;
    }
}