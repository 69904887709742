.testimonials {
    margin-left: 4.5rem;
    margin-top: 1.2rem;
}

.testimonials span {
    font-family: "josefin sans";
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to right, green, rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.testimonials hr {
    color: darkgreen;
    border: 2px solid;
    margin-top: 0;
    max-width: 10rem;
    opacity: 0.7;
    border-radius: 3px;
}

.testimonials-body {
    margin-right: 2.5rem;
}

.testimonials-body h3 {
    font-size: 20px;
    margin-left: 6.5rem;
    margin-bottom: 2rem;
    font-family: "Andika", sans-serif;
}

.slide-container-testimonials {
    width: 90%;
    padding: 10px 10px;
}

.slide-content-testimonials {
    margin: 0 40px;
    overflow: hidden !important;
}

.card {
    /* width: 280px; */
    border-radius: 10px;
    background-color: white;
    min-width: 250px !important;
}

.card-content-testimonials,
.desc-content-testimonials {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    justify-content: space-between;
}

.card-content-testimonials {
    row-gap: 5px;
    padding: 0;
}

.card-desc-testimonials {
    position: relative;
    padding: 10px;
}

.card-desc-testimonials .desc-testimonials {
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: "josefin sans", sans-serif;
    font-style: italic;
}

.card-content-testimonials .name-testimonials {
    font-size: 18px;
    font-family: "josefin sans", sans-serif;
    /* align-items: center; */
    /* justify-content: center; */
    text-align: center;
    margin-top: -20px;
    padding-bottom: 1rem;
}

.testimonials-body .swiper {
    padding-bottom: 3rem !important;
}

@media (max-width: 768px){
    .testimonials {
        margin-left: 0.5rem;
        margin-top: 1rem;
    }

    .testimonials-body h3 {
        font-size: 20px;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
        font-family: "Andika", sans-serif;
    }
}
