.main-body-image {
    background-image: url('../images/Orange-Mesh-Lines-Backgrounds.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    /* min-height: 500px; */
}

.coming-soon {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.coming-soon img {
    width: 30%;
    /* background-color: white; */
}
