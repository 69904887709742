.fixed-social-links {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10000;
    background: white;
    padding: 0 0.5rem;
    box-shadow: -5px 0px 4px gray;
    border-radius: 9px 0 0 9px;
}

.fixed-social-links .social-links-bg {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    margin: 1rem 0;
}

.fixed-social-links .social-links-bg img {
    width: 25px;
    height: 25px;
    -o-object-fit: cover;
    object-fit: cover;
}

.fixed-social-links .social-links-bg:hover {
    transform: scale(1.4);
}