.main-section {
    padding-top: 1rem;
    margin-bottom: -4rem;
}

.main-section img {
    width: 100%;
    height: auto;
    /* margin-left: 5rem; */
    /* padding-right: 10rem; */
    align-items: center;
    justify-content: center;
    object-fit: contain;
    /* border-radius: 0.5rem; */
}

.main-section h2 {
    position: relative;
    text-align: center;
    top: -13rem;
    color: white;
    text-transform: capitalize;
    /* font-size: 25px; */
    font-family: "josefin sans", sans-serif;
    font-weight: bold;
}

.main-section h3 {
    position: relative;
    text-align: center;
    top: -12rem;
    color: white;
    text-transform: capitalize;
    /* font-size: 25px; */
    font-family: "josefin sans", sans-serif;
    font-weight: bold;
}

.introduction,
.features {
    margin: 2rem;
    /* margin-left: 3rem; */
}

.introduction span,
.features span {
    font-family: "josefin sans", sans-serif;
    /* font-size: 25px; */
    font-weight: 600;
    background-image: linear-gradient(to right, green, rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.introduction hr,
.features hr {
    color: darkgreen;
    border: 2px solid;
    margin-top: 0;
    max-width: 10rem;
    opacity: 0.7;
    border-radius: 3px;
}

.intro-content {
    display: flex;
    margin-left: 3rem;
    margin-right: 4rem;
}

.intro-content p {
    margin-left: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 3rem;
    text-align: justify;
    font-size: 18px;
    font-weight: 100;
    font-family: "Andika", sans-serif;
    background: white;
}

.content-img img {
    width: 550px;
    margin-right: 3rem;
}

.accordion {
    /* margin: 3rem; */
    width: 80%;
    /* margin-right: 5rem; */
    margin-left: 5rem;
}

.accordion>li {
    list-style: none;
    width: 100%;
    margin: 20px;
    padding: 10px;
    border-radius: 8px;
    /* background: #f8fcff; */
    background-image: linear-gradient(to right, darkorange, white);
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.15);
}

.accordion>li .label {
    display: flex;
    align-items: center;
    /* padding: 10px; */
    font-size: 20px;
    font-weight: 500;
    /* font-weight: bold; */
    cursor: pointer;
    font-family: "Andika", sans-serif;
}

.label::before {
    content: '+';
    margin-right: 10px;
    font-size: 24px;
    font-weight: 600;
}

input[type="radio"] {
    display: none;
}

.accordion .content {
    /* color: #555; */
    padding: 0 10px;
    line-height: 26px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s;
}

.accordion .content .text {
    margin-left: 1.5rem;
    font-family: "Andika", sans-serif;
    font-size: 17px;
    position: relative;
    top: -0.8rem;
}

.accordion .content li,
.accordion .content p {
    margin-left: 3rem;
    font-family: "Andika", sans-serif;
    font-size: 17px;
}

.accordion .content li {
    list-style-type: square;
    margin-bottom: 1rem;
    font-weight: 500;
}

.accordion input[type="radio"]:checked+.label+.content {
    max-height: 1000px;
    padding: 10px 10px 20px;
}

.accordion input[type="radio"]:checked+.label::before {
    content: '-';
}

@media (max-width: 768px) {
    .content-img img {
        display: none;
    }

    .main-section h2 {
        position: relative;
        text-align: center;
        top: -5rem !important;
        color: white !important;
        text-transform: capitalize;
        font-size: 15px;
        font-family: "josefin sans", sans-serif;
        font-weight: bold;
    }

    .main-section h3 {
        position: relative;
        text-align: center;
        top: -5rem !important;
        color: white;
        text-transform: capitalize;
        font-size: 14px;
        font-family: "josefin sans", sans-serif;
        font-weight: bold;
    }

    .introduction,
    .features {
        margin-left: 2rem !important;
        width: 80%;
        /* background-color: blue; */
    }

    .introduction span,
    .features span {
        font-family: "josefin sans", sans-serif;
        /* font-size: 25px; */
        font-weight: 600;
        background-image: linear-gradient(to right, green, rgb(230, 115, 15));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .intro-content {
        display: flex;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .intro-content p {
        width: 90%;
        margin-left: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-right: 1rem;
        margin-top: -1rem;
        text-align: justify;
        font-size: 18px;
        font-weight: 100;
        font-family: "Andika", sans-serif;
        background: white;
    }

    .accordion {
        /* margin: 3rem; */
        width: 80%;
        /* margin-right: 5rem; */
        margin-left: 0;
    }
}