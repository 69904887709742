.services {
    margin-left: 4.5rem;
    margin-top: 1.2rem;
}

.services span {
    font-family: "josefin sans";
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to right, green, rgb(230, 115, 15));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services hr {
    color: darkgreen;
    border: 2px solid;
    margin-top: 0;
    max-width: 10rem;
    opacity: 0.7;
    border-radius: 3px;
}

.service-body {
    font-family: "Andika", sans-serif;
    border-radius: 5px;
    margin-right: 2.5rem;
    /* background-color: gray; */
    /* padding-bottom: 5rem; */
}

.service-body h3 {
    font-size: 20px;
    margin-left: 6.5rem;
    margin-bottom: 2rem;
}

.slide-container-service {
    /* max-width: 1120px; */
    width: 90%;
    /* background-color: gray; */
    padding: 10px 10px;
}

.slide-content-service {
    margin: 0 40px;
    overflow: hidden !important;
}

.card {
    /* width: 280px; */
    border-radius: 10px;
    background-color: white;
    min-width: 250px !important;
}

.desc-content-service,
.btn-content-service,
.card-content-service {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px 10px;
    justify-content: space-between;
    /* text-align: center; */
    /* width: 100%; */
}

.card-content-service img {
    width: 40px;
    height: 40px;
}

.img-service {
    position: relative;
    left: 5px;
    top: 5px;
}

.desc-content-service {
    row-gap: 5px;
    padding: 10px 0;
}

.card-desc-service {
    position: relative;
    padding: 10px;
    /* width: 100%; */
    /* background-color: #124ff8; */
}

.card-desc-service .desc-service {
    height: 100%;
    width: 100%;
    /* text-align: center; */
    text-align: justify;
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -10px;
    margin-bottom: -20px;
    font-family: "Andika", sans-serif;
    /* background-color: blue; */
}

.name-service {
    font-size: 20px;
    /* font-weight: 300;0 */
    color: black;
    margin-top: 10px;
    margin-bottom: -10px;
    font-family: "josefin sans", sans-serif;
    position: absolute;
    left: 70px;
}

.hr-service {
    color: darkorange;
    opacity: 0.8;
}

.button-service {
    border: none;
    font-size: 16px;
    color: white;
    padding: 5px 10px;
    background-color: #6088f6;
    border-radius: 5px;
    margin: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    /* transition: 0.5s ease-in-out; */
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.button-service a {
    text-decoration: none;
    color: white;
}

.button-service:hover {
    background: #124ff8;
}

.button-service a:hover {
    color: white;
}

.card:hover .desc-content-service {
    opacity: 0.5;
}

.card:hover .hr-service {
    opacity: 0.4;
}

.card:hover .button-service {
    opacity: 1;
}

.service-body .swiper {
    padding-bottom: 3rem !important;
}

@media (max-width: 768px){
    .services {
        margin-left: 0.5rem;
        margin-top: 1.2rem;
    }
    .service-body {
        font-family: "Andika", sans-serif;
        border-radius: 5px;
        margin-right: 2rem;
        /* background-color: gray; */
        /* padding-bottom: 5rem; */
    }
    
    .service-body h3 {
        font-size: 20px;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }
}