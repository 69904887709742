.slider-section {
    padding-top: 1rem;
}

.slider-section img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    object-fit: contain;
    border: 5px;
}

.slider-section h3,
p {
    color: black;
    text-align: left;
    font-family: "josefin sans", sans-serif;
}

.caption-content-slider,
.btn-content-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.caption-content-slider .slider-h3 {
    position: absolute;
    top: 8rem;
    left: 2rem;
    color: black;
}

.caption-content-slider .slider-p {
    position: absolute;
    top: 10.5rem;
    left: 2rem;
    font-size: 18px;
    color: black;
}

.caption-content-slider .slider-p1 {
    position: absolute;
    top: 12rem;
    left: 2rem;
    font-size: 18px;
    color: black;
}

.btn-content-slider .btn-sliderimg {
    position: absolute;
    bottom: 2rem;
    right: 4rem;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: "Andika", sans-serif;
    background: rgb(255, 184, 53);
    /* font-weight: bold; */
}

.btn-content-slider .btn-sliderimg a {
    text-decoration: none;
    color: black;
}

.btn-content-slider .btn-sliderimg:hover {
    background-color: darkorange;
}

.btn-content-slider .btn-sliderimg:hover a {
    color: white;
}

.modal {
    font-family: "Andika", sans-serif;
}

.modal-header {
    background-image: linear-gradient(to right, darkorange, white);
}

.contact-icon {
    width: 40px;
}

.contact-span {
    margin: 1rem;
}

.error-message {
    color: red;
}

.modal-footer {
    margin-bottom: -1rem;
}

.modal-footer Button {
    background-color: darkorange;
    border-color: darkorange;
}

.modal-footer Button:hover {
    background-color: rgb(255, 173, 73);
    border-color: rgb(255, 173, 73);
    color: black;
}

@media(max-width:768px) {

    .caption-content-slider .slider-h3 {
        position: absolute;
        font-size: 15px;
        top: 0.5rem;
        left: 1rem;
        color: orange;
    }

    .caption-content-slider .slider-p {
        position: absolute;
        top: 1.5rem;
        left: 1rem;
        font-size: 10px;
        color: orange;
    }

    .caption-content-slider .slider-p1 {
        position: absolute;
        top: 2.5rem;
        left: 1rem;
        font-size: 10px;
        color: orange;
    }

    .btn-content-slider .btn-sliderimg {
        position: absolute;
        bottom: 1rem;
        right: 18rem;
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font-family: "Andika", sans-serif;
        background: rgb(255, 184, 53);
        /* font-weight: bold; */
    }
}